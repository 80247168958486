import { getRequest, postRequest, downloadRequest } from "@/libs/axios";

// ------------------------短信管理-----------------------
// 短信记录--列表
export function list(params) {
    return getRequest("/yethan/webNoticeSms/listPage", params);
}
// 短信记录--导出
export function listExport(params) {
    return downloadRequest("/yethan/webNoticeSms/exportExcel", params);
}
// 短信模板列表
export function templateList(params) {
    return getRequest("/yethan/webNoticeSms/template/list", params);
}
// 发送短信
export function sendSms(params) {
    return postRequest("/yethan/webNoticeSms/sendSms", params);
}
