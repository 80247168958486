<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  .ck-editor__editable {
    min-height: 185px;
  }

  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px;
  }

  /deep/.el-input__inner {
    height: 30px;
    line-height: 30px;
    border-color: rgb(23, 118, 210);
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import {
    list,
    templateList,
    sendSms,
    listExport
  } from "@/api/log/sms.js";


  /**
   * 系统设置--短信
   */
  export default {
    components: {
      Layout,
      // MessageHeader,



    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "系统管理",
            active: true
          }
        ],
        query: {
          keyword: "",
          sendType: "",
          sendFrom: "",
          begDate: "",
          endDate: "",
          pageNum: 1,
          pageSize: 20
        },
        list: [],
        templateList: [],
        templateCode: "",
        smsEditList: [],
        smsEditContent: "",
        smsEditMemo: "",
        acceptUsers: [{
            xm: '小张',
            yhzh: '123',
            yddh: '18883344509'
          },
          {
            xm: '小红',
            yhzh: '456',
            yddh: '18883344508'
          },
        ],
        showmodal: false,
        // 当前页
        currentPage: 1,
        // 每页显示
        perPage: 20,
        // 总数
        total: 0
      };
    },
    mounted() {
      this.getList();
      this.getTemplateList();
    },
    computed: {
      rows() {
        return this.list.length;
      },
    },
    methods: {
      /** 查询短信发送记录列表 */
      getList() {
        this.query.pageNum = this.currentPage
        this.query.pageSize = this.perPage
        list(this.query).then(res => {
          if (res.status) {
            this.list = res.data
            this.total = res.total
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.currentPage = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      /** 查询短信模板列表 */
      getTemplateList() {
        if (this.templateList == '') {
          templateList().then(res => {
            if (res.status) {
              this.templateList = res.data
            }
          })
        }
      },
      /** 根据所选模板填充编辑区域 */
      changeSmsContent() {
        var code = this.templateCode;
        var tempList = [];
        for (let item of this.templateList) {
          if (item.templateCode == code) {
            this.smsEditContent = item.templateContent
            this.smsEditMemo = item.templateMemo
            if (item.templateParam1 != null) {
              tempList.push({
                title: item.templateParam1,
                value: "",
              })
            }
            if (item.templateParam2 != null) {
              tempList.push({
                title: item.templateParam2,
                value: "",
              })
            }
            this.smsEditList = tempList;
          }
        }
      },
      /** 发送短信 */
      smsSend() {
        if (this.smsEditList != '') {
          // 校验参数填写
          var checkFlag = 1;
          for (let item of this.smsEditList) {
            if (item.value == '') {
              checkFlag = 0
            }
          }
          if (!checkFlag) {
            this.$message({
              type: 'error',
              message: "请完善参数内容"
            });
            return;
          }
          // 校验收信人
          if (this.acceptUsers.length == 0) {
            this.$message({
              type: 'error',
              message: "请选择接受对象"
            });
            return;
          }
          // 发送短信
          var params = {
            users: this.acceptUsers,
            templateCode: this.templateCode,
            templateParam1: this.smsEditList[0].value,
            templateParam2: this.smsEditList.length > 1 ? this.smsEditList[1].value : ''
          }
          this.$confirm('是否确认发送短信?', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(function() {
            return sendSms(params);
          }).then((res) => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: "发送成功"
              });
              this.showmodal = false
              this.templateCode = ""
              this.smsEditList = []
              this.smsEditContent = ""
              this.smsEditMemo = ""
              this.acceptUsers = []
            }
          })
        }
      },
      /** 失去焦点修改input中的值 */
      blurChange(row, event) {
        row.value = event.target.value
      },
      exportExcel() {
        listExport(this.query).then(res => {
          if (!res.data) {
            return
          }
          let url = window.URL.createObjectURL(new Blob([res.data]))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', '短信管理.xlsx')

          document.body.appendChild(link)
          link.click()
        })
      },
      handleSizeChange(val) {
        this.perPage = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getList();
      },
    }
  };
</script>

<template>
  <Layout>
    <!--<MessageHeader :items="items" />-->

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 500px;">
          <div class="card-body">
            <div class="pb-3 border-dash  check-table-top">
              <div class="flexList">
                <el-select class=" mr-2 blueBorder" style="width: 10%;" placeholder="选择短信类别" v-model="query.sendType"
                  size="small">
                  <el-option value="" label="所有类型"></el-option>
                  <el-option value="短信验证码" label="短信验证码"></el-option>
                  <el-option value="消息广播" label="消息广播"></el-option>
                  <el-option value="事务通知" label="事务通知"></el-option>
                </el-select>

                <el-select class=" mr-2 blueBorder" style="width: 10%;" placeholder="选择短信发自模块" v-model="query.sendFrom"
                  size="small">
                  <el-option value="" label="所有模块"></el-option>
                  <el-option value="注册" label="注册"></el-option>
                  <el-option value="找回密码" label="找回密码"></el-option>
                </el-select>

                <el-date-picker v-model="query.begDate" value-format="yyyy-MM-dd HH:mm:ss" class="w-10 mr-2"
                  type="datetime" size="small" placeholder="选择开始时间"> </el-date-picker>
                <el-date-picker v-model="query.endDate" value-format="yyyy-MM-dd HH:mm:ss" class="w-10 mr-2"
                  type="datetime" size="small" placeholder="选择结束时间"> </el-date-picker>
                <input placeholder="输入关键字" v-model="query.keyword" class="h30 form-control border-blue mr-2 w-15"
                  maxlength="50" />

                <button type="button" class="btn btn-info h30 flexList mr-2 " @click="searchClick()"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button v-b-modal.send-message variant="outline-info" class="flexList mr-2 condition w-md"><i
                    class="iconfont icon-send mr-2"></i>发送短信</b-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab" @click="exportExcel"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <!-- <div class="border-blue export-tab"><i class="iconfont icon-data mr-2"></i>dbf</div> -->
              </div>
            </div>

            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;"> 序号</th>
                    <th style="width: 6%;">短信类型</th>
                    <th style="width: 6%;">发自模块</th>
                    <th style="width: 8%;">模板号</th>
                    <th style="width: 7%;">接收手机</th>
                    <th style="width: 5%;">接收人</th>
                    <th>短信内容 </th>
                    <th style="width: 5%;">发信人 </th>
                    <th style="width: 5%;">发送状态</th>
                    <th style="width: 12%;">发送时间</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, index) in list" :key="index">
                    <td>{{index+1+(currentPage-1)*perPage}}</td>
                    <td>{{obj.sendType}}</td>
                    <td>{{obj.sendFrom}}</td>
                    <td>{{obj.templateCode}}</td>
                    <td>{{obj.mobilePhone}}</td>
                    <td>{{obj.userName}}</td>
                    <td>{{obj.smsContent}}</td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{obj.sendResult == '1' ? '成功' : '失败'}}</td>
                    <td>{{obj.sendTime}}</td>
                  </tr>
                </tbody>

              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-size="perPage" layout="total, sizes,jumper, prev, pager, next"
                :total="total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <b-modal id="send-message" v-model="showmodal" centered title="短信发送" size="lg" title-class="font-18" hide-footer>
      <div class="d-flex mb-2">
        <div class="message-name">短信模板</div>
        <div class="massge-val">
          <el-select class=" mr-2 blueBorder" style="width: 30%;" placeholder="请选择短信模板" v-model="templateCode"
            @change="changeSmsContent()" size="small">
            <el-option :value="item.templateCode" v-for="item in templateList" :key="item.sid"
              :label="item.templateTitle">{{item.templateTitle}}
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="message-name">模板内容</div>
        <div class="massge-val line2">
          {{this.smsEditContent}}
        </div>
      </div>
      <div class="d-flex mb-3" v-if="this.smsEditMemo">
        <div class="message-name">模板备注</div>
        <div class="massge-val line2">
          {{this.smsEditMemo}}
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="message-name">参数内容</div>
        <div>
          <div class="flexList mb-2" v-for="item in smsEditList" :key="item.title">
            <div class="message-name">{{item.title}}</div>
            <input type="text" name="" :value="item.value" @blur="blurChange(item, $event)"
              class="form-control border-blue w-50 h30" />
            <span class="grey-time ml-2"></span>
          </div>
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="message-name">接收人</div>
        <div class="w-100">
          <textarea rows="4" class="form-control  line3" placeholder="点击下方按钮进行接受人选取" readonly></textarea>
        </div>

      </div>
      <div class="d-flex mb-3">
        <div class="message-name"></div>
        <div class="w-100">
          <b-button variant="outline-info" class="flexList mr-2 condition h30"><i
              class="iconfont icon-send mr-2"></i>点击选择用户</b-button>
        </div>

      </div>

      <div class="text-center message-modal"><button type="button" class="btn btn-info h30  mr-2"
          @click="smsSend()">提交</button>
        <button type="button" class="btn btn-secondary h30  mr-2" @click="showmodal = false">取消</button>
      </div>
    </b-modal>

    <!-- 弹窗结束 -->
  </Layout>
</template>
